<template>
  <div class="main_container">
    <div class="main_page">
      <div class="page_title">{{ $route.meta.title }}</div>
      <div class="page_containt">
        <div class="page_search">
          <el-row type="flex">
            <el-col class="search_col">
              <el-button type="primary" size="small" @click="doForm(0)">新增权限组</el-button>
            </el-col>
          </el-row>
        </div>
        <div class="page_box" ref="pageBox">
          <el-table v-loading="loading" default-expand-all row-key="id" size="small" class="el_table"
            cell-class-name="el_table_cell" header-row-class-name="el_table_header" :data="listData" :height="tableHeight"
            :tree-props="{ children: 'sub_sort' }" style="width: 100%">
            <el-table-column prop="name" label="名称"> </el-table-column>
            <el-table-column fixed="right" align="right" header-align="right" label="操作" width="140">
              <template slot-scope="scope">
                <el-button class="btn" type="text" icon="el-icon-edit" size="small" @click="doForm(scope.row.id)">
                  编辑
                </el-button>
                <el-button @click="deleteItem(scope.row)" class="btn" type="text" icon="el-icon-delete" size="small">
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <el-drawer :title="formTitle" size="60%" :visible.sync="drawer" :direction="direction" :wrapperClosable="false"
      :close-on-press-escape="false">
      <div class="drawer_box">
        <el-form ref="groupForm" :rules="groupRules" :model="groupForm" label-width="80px" size="small">
          <el-form-item label="名称" size="small" prop="name">
            <el-input v-model="groupForm.name" style="width: 300px" placeholder="名称"></el-input>
          </el-form-item>
          <el-form-item label="功能" size="small">
            <el-tree :data="allOperate" show-checkbox :props="defaultProps" ref="allOperate" default-expand-all
              node-key="id">
            </el-tree>
          </el-form-item>
          <div class="drawer_footer" style="left: 40%">
            <el-button type="primary" size="small" native-type="submit" @click.native.prevent="handleSubmit"
              :loading="btnLogining">提交</el-button>
            <el-button size="small" @click="drawer = false">取消</el-button>
          </div>
        </el-form>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { onTableHeight } from "@/lib/table";
import userApi from "@/api/user";
export default {
  data() {
    return {
      loading: false,
      btnLogining: false,
      drawer: false,
      direction: "rtl",
      formTitle: "",
      groupID: 0,
      groupForm: {
        name: "",
        groupJson: [],
      },
      groupRules: {
        name: [
          { required: true, message: "请输入名称", trigger: "blur" },
          { min: 1, max: 100, message: "长度不超过100个字符" },
        ],
      },
      allOperate: [],
      defaultProps: {
        children: "menus",
        label: "name",
      },
      listData: [],
      tableHeight: window.innerHeight - 180,
    };
  },
  mounted() {
    onTableHeight(this, false);
    this.getList(true);
  },
  methods: {
    //查询
    handleSearch() {
      this.getList(true);
    },
    resetSearchForm(formName) {
      this.searchForm = {
        key: "",
      };
      this.getList(true);
    },
    //表单
    doForm(id) {
      this.formTitle = id == 0 ? "新增权限组" : "编辑权限组";
      this.groupID = id;
      this.groupForm = {
        name: "",
        groupJson: [],
      };
      if (this.$refs.groupForm) this.$refs.groupForm.resetFields();
      let message = this.$message({
        message: "加载中",
        iconClass: "el-icon-loading",
        customClass: "message-loading",
        duration: 0,
        offset: 60,
      });
      userApi
        .groupInfo({
          id: this.groupID,
        })
        .then((res) => {
          this.drawer = true;
          if (this.groupID > 0) {
            this.groupForm = {
              name: res.group.name,
            };
          } else {
            this.groupForm = {
              name: "",
            };
          }
          this.allOperate = res.all_operate;
          let keys = [];
          res.group_infos.forEach((item) => {
            keys.push(parseInt(item.operate_id));
          });
          this.$nextTick(() => {
            if (this.$refs.allOperate)
              this.$refs.allOperate.setCheckedKeys(keys);
          });
        })
        .catch(() => { })
        .finally(() => {
          message.close();
        });
    },
    //列表
    getList(loading) {
      this.loading = loading;
      userApi
        .groupList()
        .then((res) => {
          this.listData = res.list;
        })
        .catch((res) => {
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    //删除分组
    deleteItem(row) {
      this.$confirm("确定是否删除该权限组?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let message = this.$message({
            message: "请求中",
            iconClass: "el-icon-loading",
            customClass: "message-loading",
            duration: 0,
            offset: 60,
          });
          userApi
            .groupDelete({
              id: row.id,
            })
            .then((res) => {
              this.$message({
                message: "删除成功",
                type: "success",
                offset: 60,
              });
              this.getList(true);
            })
            .catch((res) => { })
            .finally(() => {
              message.close();
            });
        })
        .catch(() => { });
    },
    handleSubmit() {
      this.$refs.groupForm.validate((valid) => {
        if (valid) {
          let groupJson = [];
          let temJson = this.$refs.allOperate.getCheckedNodes();
          if (temJson.length <= 0) {
            this.$message({
              message: "请选择功能",
              type: "error",
              offset: 60,
            });
            return;
          }
          console.log(temJson);
          temJson.forEach((item) => {
            groupJson.push(item.id);
          });
          this.btnLogining = true;
          userApi
            .groupForm({
              id: this.groupID,
              name: this.groupForm.name,
              group_json: JSON.stringify(groupJson),
            })
            .then((res) => {
              this.$message({
                message: "提交成功",
                type: "success",
                offset: 60,
              });
              this.drawer = false;
              this.getList(true);
            })
            .catch((res) => { })
            .finally(() => {
              this.btnLogining = false;
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style scope></style>
